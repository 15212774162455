import React, { useRef, useEffect } from "react"

import PropTypes from "prop-types"

import { convertStringToCamelcase } from "../../../helpers/helpers"

import "./modal.scss"

export const Modal = ({
  title,
  className,
  onClose,
  buttons,
  isActive,
  onFormSubmit,
  children,
}) => {
  const modal = useRef(null)
  const button = useRef(null)

  useEffect(() => {
    isActive ? openDialog() : closeDialog()
  }, [isActive])

  const handleOnKeyDown = ({ keyCode }) => {
    if (isActive && keyCode === 27) {
      closeDialog()
    }
  }

  const openDialog = () => {
    modal.current.classList.add("show")
    modal.current.style.opacity = "0"
    modal.current.style.display = "block"
    setTimeout(() => {
      modal.current.style.opacity = "1"
      button.current.focus()
    }, 10)
  }

  const closeDialog = () => {
    onClose()
    modal.current.classList.remove("show")
    modal.current.style.opacity = 0
    setTimeout(() => {
      if (modal.current) {
        modal.current.style.display = "none"
      }
    }, 150)
  }

  const handleOnNoSubmit = e => {
    e.preventDefault()
  }

  const titleId = `${convertStringToCamelcase(title.label)}Label`

  return (
    <div
      onKeyDown={handleOnKeyDown}
      role="dialog"
      ref={modal}
      className={`${className} modal fade overflow-scroll`}
      tabIndex={isActive ? 0 : -1}
      aria-hidden={!isActive}
      aria-labelledby={titleId}
    >
      <form
        className="modal-dialog mb-5"
        onSubmit={onFormSubmit || handleOnNoSubmit}
      >
        <div className="modal-content border-0">
          <div className="modal-body p-4">
            <div className="d-flex justify-content-between align-items-start">
              <h2
                id={titleId}
                className={`modal-title h3 mb-2 ${title.className}`}
              >
                {title.label}
              </h2>

              <button
                ref={button}
                type="button"
                className="close webchat-prompt-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeDialog}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {children}

            <div className="d-flex justify-content-end">
              {buttons.map((button, i) => (
                <button
                  {...(button.id ? { id: button.id } : {} )}
                  key={i}
                  type={button.type || "button"}
                  data-dismiss="modal"
                  onClick={button.onClick}
                  type="submit"
                  aria-label={button.ariaLabel}
                  className={`btn btn-${button.variant} ${button.className}`}
                >
                  {button.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

Modal.propTypes = {
  title: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["button", "submit"]),
      variant: PropTypes.oneOf(["link", "primary", "secondary"]),
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      classNames: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
}
