import { decorate, observable, action } from "mobx"

export class HeaderStore {
  isNavToggled = false

  setIsNavToggled(isToggled) {
    this.isNavToggled = isToggled
  }
}

decorate(HeaderStore, {
  isNavToggled: observable,
  setIsNavToggled: action,
})
