import React from "react"

import "./noscript.scss"

export const NoScript = () => (
  <noscript
    dangerouslySetInnerHTML={{
      __html: `<div class="noscript">
            To use this website, you may need to enable JavaScript. 
            Click
            <a href="https://enablejavascript.co/" class="noscript-link" aria-label="Enable JavaScript guide">here</a> 
            to find out more.
        </div>`,
    }}
  />
)
