// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-source-wagtail-preview-template-js": () => import("./../../../node_modules/gatsby-source-wagtail/preview-template.js" /* webpackChunkName: "component---node-modules-gatsby-source-wagtail-preview-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-northern-ireland-js": () => import("./../../../src/pages/northern-ireland.js" /* webpackChunkName: "component---src-pages-northern-ireland-js" */),
  "component---src-pages-scotland-js": () => import("./../../../src/pages/scotland.js" /* webpackChunkName: "component---src-pages-scotland-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-basic-page-basic-page-js": () => import("./../../../src/templates/basic-page/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-basic-page-js" */),
  "component---src-templates-fact-sheet-fact-sheet-js": () => import("./../../../src/templates/fact-sheet/fact-sheet.js" /* webpackChunkName: "component---src-templates-fact-sheet-fact-sheet-js" */),
  "component---src-templates-fact-sheet-library-fact-sheet-library-js": () => import("./../../../src/templates/fact-sheet-library/fact-sheet-library.js" /* webpackChunkName: "component---src-templates-fact-sheet-library-fact-sheet-library-js" */),
  "component---src-templates-grid-page-grid-page-js": () => import("./../../../src/templates/grid-page/grid-page.js" /* webpackChunkName: "component---src-templates-grid-page-grid-page-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-sample-letter-library-sample-letter-library-js": () => import("./../../../src/templates/sample-letter-library/sample-letter-library.js" /* webpackChunkName: "component---src-templates-sample-letter-library-sample-letter-library-js" */),
  "component---src-templates-sample-letter-sample-letter-js": () => import("./../../../src/templates/sample-letter/sample-letter.js" /* webpackChunkName: "component---src-templates-sample-letter-sample-letter-js" */),
  "component---src-templates-simple-step-simple-step-js": () => import("./../../../src/templates/simple-step/simple-step.js" /* webpackChunkName: "component---src-templates-simple-step-simple-step-js" */),
  "component---src-templates-steps-root-steps-root-js": () => import("./../../../src/templates/steps-root/steps-root.js" /* webpackChunkName: "component---src-templates-steps-root-steps-root-js" */)
}

