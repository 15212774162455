import React, { useEffect, useState } from "react"

import { Modal } from "../modal/modal"
import { isBrowser, triggerWebchat } from "../../../helpers/helpers"

import "./webchat-prompt.scss"

export const WebchatPrompt = () => {
  const [isActive, setIsActive] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const twoMinutesInMilliseconds = 60000

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  useEffect(() => {
    let hour = new Date().getHours();
    let day = new Date().getDay(); 
    let dayOfMonth = new Date().getDate();  
    let monthOfYear = new Date().getMonth(); 
    let bankHoliday;
    if (dayOfMonth == 1 && monthOfYear == 0 || dayOfMonth == 25 && monthOfYear == 11 || dayOfMonth == 26 && monthOfYear == 11) { 
      bankHoliday = true;
      console.log('bankHoliday', bankHoliday)
    } else {
      bankHoliday = false;
      console.log('bankHoliday', bankHoliday)
    }
    
    if ( day != 0 && !bankHoliday) {
      console.log('1')
     if (day > 0 && day < 6  && hour >= 9 && hour <= 16) {
        setIsOpen(true);
        console.log('2')
      } else {
        console.log('3')
      }
    }  else {
      console.log('4')
    }

    // Popup disabled
    //setIsOpen(false)
  }, []);

  useEffect(() => {
    console.log('isOpen', isOpen)
    if (
      isBrowser && isOpen &&
      localStorage.getItem("isWebchatPromptDismissed") !== "true" &&
      window.location.pathname.indexOf('/lloydsbank') == -1 &&
      window.location.pathname.indexOf('/bankofscotland') == -1
    ) {
      setTimeout(openDialog, twoMinutesInMilliseconds)
    }
  }, [isOpen])

  const openDialog = () => {
    setIsActive(true)
  }

  const closeDialog = () => {
    setIsActive(false)
    if (isCheckboxChecked && isBrowser) {
      localStorage.setItem("isWebchatPromptDismissed", "true")
    }
  }

  const handleOnCheckboxChange = ({ target }) => {
    setIsCheckboxChecked(target.checked)
  }

  const handleOnStartWebchat = () => {
    closeDialog()
    triggerWebchat()
  }

  return (
    <Modal
      title={{ label: "Webchat" }}
      onClose={closeDialog}
      className="webchat-prompt"
      isActive={isActive}
      buttons={[
        {
          id: "popup",
          variant: "secondary",
          label: "Start webchat",
          ariaLabel: "Start webchat",
          className: "mt-2",
          onClick: handleOnStartWebchat,
        },
      ]}
    >
      <p>
        If you're stuck or have a question, chat live to an expert Business
        Debtline adviser.
      </p>

      <label className="d-flex p align-items-center">
        <span>
          Tick this box if you don’t want to see this message again:&nbsp;
        </span>
        <input type="checkbox" onChange={handleOnCheckboxChange} />
      </label>
    </Modal>
  )
}
