import React from "react"

import { Provider } from "mobx-react"

import { RegionStore } from "./src/stores/RegionStore"
import { HeaderStore } from "./src/stores/HeaderStore"
import { NoScript } from "./src/components/base/noscript/noscript"
import { CookiePolicy } from "./src/components/modules/cookie-policy/cookie-policy"
import { WebchatPrompt } from "./src/components/modules/webchat-prompt/webchat-prompt"
import { triggerWebchat } from "./src/helpers/helpers"

import "./src/sass/vendor/vendor.scss"
import "./src/sass/utils/utils.scss"
import "./src/sass/base/base.scss"

export const wrapRootElement = ({ element }) => {
  window.addEventListener(
    "hashchange",
    (e) => {
      if (location.hash === "#webchat") {
        triggerWebchat()
        location.hash = ''
      }
    },
    false
  )

  window.addEventListener(
    "load",
    (e) => {
      if (location.hash === "#webchat") {
        triggerWebchat()
        location.hash = ''
      }
    },
    false
  )

  return (
    <Provider regionStore={new RegionStore()} headerStore={new HeaderStore()}>
      <NoScript />
      <CookiePolicy />
      <WebchatPrompt />

      {element}
    </Provider>
  )
}
