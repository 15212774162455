import React, { useState } from "react"

import { navigate } from "gatsby"

import { Modal } from "../modal/modal"
import { isBrowser } from "../../../helpers/helpers"

export const CookiePolicy = () => {
  const [cookieData, setCookieData] = useState(isBrowser && document.cookie)

  const [isActive, setIsActive] = useState(
    isBrowser && document.cookie.indexOf('isCookiePolicyDismissed') == -1 && localStorage.getItem("isCookiePolicyDismissed") !== "true"
  )

  const closeDialog = () => {
    setIsActive(false)
    
    if (isBrowser) {
      var cookieDate = new Date()
      cookieDate.setFullYear(cookieDate.getFullYear() + 1)
      document.cookie = "isCookiePolicyDismissed=true;expires=" + cookieDate.toUTCString() + ";domain=.businessdebtline.org;path=/"
    }
  }

  const closeDialogAndRedirect = () => {
    closeDialog()
    navigate("cookies-policy")
  }

  const buttons = [
    {
      variant: "link",
      label: "Learn more",
      ariaLabel: "Learn more",
      className: "mr-1",
      onClick: closeDialogAndRedirect,
    },
    {
      variant: "secondary",
      label: "Accept",
      ariaLabel: "Accept terms",
      onClick: closeDialog,
    },
  ]

  return (
    <Modal
      title={{ label: "We use Cookies on this site" }}
      isActive={isActive}
      onClose={closeDialog}
      buttons={buttons}
    >
      <p>
        Click <strong>Learn more</strong> to view our Cookies policy or&nbsp;
        <strong>Accept</strong> to close this box.
      </p>

      <hr />
    </Modal>
  )
}
