export const isBrowser = typeof window !== `undefined`

export const isCurrentPage = page => {
  if (typeof window !== "undefined") {
    return window.location.href.indexOf(page) > -1
  }
}

export const toFirstLetterUppercase = text =>
  text.replace(/(^.)/, m => m.toUpperCase())

export const convertStringToCamelcase = text => {
  text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
  return text.substr(0, 1).toLowerCase() + text.substr(1)
}

export const convertStringFromCamelcase = text =>
  text.replace(/([A-Z])/g, " $1").replace(/^./, string => string.toUpperCase())

const urlPattern = new RegExp("^(https?|ftp)://")
export const isInternalUrl = url => !urlPattern.test(url)

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.indexOf("safari") != -1 && userAgent.indexOf("chrome") <= -1
}

// Solution sourced from StackOverflow:
// https://stackoverflow.com/a/58245041
export const parsePythonObject = rawValue =>
  JSON.parse(
    rawValue
      .replace(new RegExp(`(?<=[a-zA-Z])'(?=[a-zA-Z ])`, "g"), "__")
      .replace(new RegExp("'", "g"), '"')
      .replace(new RegExp("__", "g"), "'")
      .replace(new RegExp("None", "g"), "null")
      .replace(new RegExp("False", "g"), "false")
      .replace(new RegExp("True", "g"), "true")
  )

// Solution sourced from StackOverflow:
// https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
export const isInViewport = element => {
  if (!element) return

  const rect = element.getBoundingClientRect(),
    width = window.innerWidth || document.documentElement.clientWidth,
    height = window.innerHeight || document.documentElement.clientHeight,
    efp = (x, y) => document.elementFromPoint(x, y)

  // Return `false` if it's not in the viewport.
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > width ||
    rect.top > height
  ) {
    return false
  }

  // Return `true` if any of element's four corners are visible.
  return (
    element.contains(efp(rect.left, rect.top)) ||
    element.contains(efp(rect.right, rect.top)) ||
    element.contains(efp(rect.right, rect.bottom)) ||
    element.contains(efp(rect.left, rect.bottom))
  )
}

// Used instead of string.prototype.matchAll for IE11 support.
// Read more: https://stackoverflow.com/questions/58003217/how-to-use-the-string-prototype-matchall-polyfill
// Source: https://stackoverflow.com/questions/6323417/regex-to-extract-all-matches-from-string-using-regexp-exec
export const findAll = (regexPattern, sourceString) => {
  let output = []
  let match
  // make sure the pattern has the global flag
  let regexPatternWithGlobal = RegExp(regexPattern, "g")
  while ((match = regexPatternWithGlobal.exec(sourceString))) {
    // get rid of the string copy
    delete match.input
    // store the match data
    output.push(match)
  }
  return output
}

export const triggerWebchat = () => {
  try {
    inisoftChatOpen()
  } catch (e) {
    console.log(e)
  }
}

export const redirectToAppropriateRegion = (isEngland, isScotland) => {
  const urlSplit = window.location.pathname.split(/\s*\-\s*/g)
  const lastWordInUrl = urlSplit[urlSplit.length - 1].replace(/\//g, "")
  const isPageEnglandExclusive = lastWordInUrl === "ew"
  const isPageScotlandExclusive = lastWordInUrl === "s"

  let newUrl = '';
  
  if (isPageEnglandExclusive && isScotland) {
    newUrl = window.location.pathname.slice(0, -3) + "s/"
  } else if (isPageScotlandExclusive && isEngland) {
    newUrl = window.location.pathname.slice(0, -2) + "ew/"
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const parameters = Object.fromEntries(urlSearchParams.entries());

  let parameterKeys = Object.keys(parameters);

  let queryString = '';

  parameterKeys.forEach((parameterKey) => {
    if(parameterKey != 'region') {
      if(queryString != '') {
        queryString += '&';
      }
      queryString += parameterKey + "=" + parameters[parameterKey];
    }
  });

  if(queryString != '' && newUrl != '') {
    newUrl += '?' + queryString;
  }

  if(newUrl != '') {
    window.location.href = newUrl;
  }
}

export const redirectToAppropriateRegionIfEw = isScotland => {
  const urlSplit = window.location.pathname.split(/\s*\-\s*/g)
  const lastWordInUrl = urlSplit[urlSplit.length - 1].replace(/\//g, "")
  const isPageEnglandExclusive = lastWordInUrl === "ew"

  let newUrl = '';
  
  if (isPageEnglandExclusive && isScotland) {
    newUrl = window.location.pathname.slice(0, -3) + "s/"
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const parameters = Object.fromEntries(urlSearchParams.entries());

  let parameterKeys = Object.keys(parameters);

  let queryString = '';

  parameterKeys.forEach((parameterKey) => {
    if(parameterKey != 'region') {
      if(queryString != '') {
        queryString += '&';
      }
      queryString += parameterKey + "=" + parameters[parameterKey];
    }
  });

  if(queryString != '' && newUrl != '') {
    newUrl += '?' + queryString;
  }

  if(newUrl != '') {
    window.location.href = newUrl;
  }
}

// URL optimise to string
// Read More: https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
// Source: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1#file-slugify-js
function slugify(string) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

export const convertToId = text =>
  text.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()

export const headerRegex = /<h(\d)[^>]*>(<[^<]*>([^<]*)<\/[^<]*>|([^<]*))<\/h\1>/g

export const addHeaderIdAttribute = (match, p1, p2) => {
  let n = p1
  let text_content = p2.replace(/(<([^>]+)>)/gi, "")
  let id = slugify(text_content)
  return `<h${n} id="${id}">${p2}</h${n}>`
}
