import { decorate, action, computed, observable } from "mobx"
import axios from "axios"

import { isBrowser } from "../helpers/helpers"

const regionsMapping = {
  EW: {
    label: "England & Wales",
  },
  S: {
    label: "Scotland",
  },
}

export class RegionStore {
  currentRegion = isBrowser ? this.getFromLocalStorage() : "EW"

  // constructor() {
  //   if (typeof localStorage != "undefined" && !localStorage.getItem("region")) {
  //     this.updateRegionToUsersRegion()
  //   }
  // }

  getFromLocalStorage() {
    return typeof localStorage != "undefined" && localStorage.getItem("region")
      ? localStorage.getItem("region")
      : "EW"
  }

  get alternateRegion() {
    return this.currentRegion === "EW" ? "S" : "EW"
  }

  get currentRegionData() {
    return regionsMapping[this.currentRegion]
  }

  get alternateRegionData() {
    return regionsMapping[this.alternateRegion]
  }

  get isEngland() {
    return this.currentRegion === "EW"
  }

  get isScotland() {
    return this.currentRegion === "S"
  }

  changeRegion(region) {
    this.currentRegion = region
    isBrowser && localStorage.setItem("region", region)
  }

  switchCurrentRegion() {
    this.currentRegion = this.alternateRegion
    isBrowser && localStorage.setItem("region", this.currentRegion)
  }

  updateRegionToUsersRegion = () => {
    axios.get("http://ip-api.com/json").then(({ data }) => {
      const ipAddress = data.query

      axios
        .get(
          `https://api.ipregistry.co/${ipAddress}?key=${process.env.IP_REGISTRY_API_KEY}`
        )
        .then(({ data }) => {
          const country = data.location.region.name
          if (country === "Scotland") {
            this.currentRegion = "S"
          } else {
            this.currentRegion = "EW"
          }
        })
        .catch(() => {
          this.currentRegion = "S"
        })
    })
  }
}

decorate(RegionStore, {
  currentRegion: observable,
  alternateRegion: computed,
  currentRegionData: computed,
  alternateRegionData: computed,
  isEngland: computed,
  isScotland: computed,
  setCurrentRegion: action,
})
